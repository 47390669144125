import Nav from "../../components/Nav";
import {useEffect, useState} from "react";
import {download, renderPartnerDocument} from "./DocumentService";

export default function Partners() {
    const [agent, setAgent] = useState('')
    const [agentId, setAgentId] = useState('')
    const [partnerId, setPartnerId] = useState('')
    const [agentAddress, setAgentAddress] = useState('')
    const [partnerName, setPartnerName] = useState('')
    const [location, setLocation] = useState('')
    const [date, setDate] = useState('')
    const [quantity, setQuantity] = useState('')
    const [r, setR] = useState('')

    const fetchPreview = async () => {
        const render = await renderPartnerDocument({
            agent,
            agentId,
            agentAddress,
            partnerName,
            partnerId,
            location,
            date,
            quantity,
        })

        setR(render);
    };

    const forceDownload = async () => {
        const fileUrl = await renderPartnerDocument({
            agent,
            agentId,
            agentAddress,
            partnerName,
            partnerId,
            location,
            date,
            quantity,
        }, true)

        await download(fileUrl)
    }


    return <div>
        <Nav />
        <div className="page page-center">
            <div className="container container-xl">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title text-uppercase">Partner contract</h3>
                                    </div>
                                    <div className="card-body">

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label text-uppercase">Variables</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <label className="form-label text-uppercase">Value</label>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">Agent</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setAgent(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">Agent NIF</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setAgentId(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">Agent Address</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setAgentAddress(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Nombre colaborador</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPartnerName(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">NIF colaborador</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPartnerId(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Ubicación</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setLocation(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Cantidad €</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setQuantity(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Fecha</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setDate(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer text-end">
                                        <button type="button" className="btn btn-primary mx-2"
                                                onClick={forceDownload}>Download
                                        </button>
                                        <button type="submit" className="btn btn-primary"
                                                onClick={fetchPreview}>Preview
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 p-5 bg-white" dangerouslySetInnerHTML={{__html: r}}></div>
                </div>
            </div>
        </div>
    </div>
}