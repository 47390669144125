import axios from 'axios';
import { API_URL } from '../../config/constants'

export const renderSellDocument = async ({
                                             propertyName,
                                             propertyId,
                                             propertyAddress,
                                             contractStartType,
                                             price,
                                             country,
                                             propertyLocality,
                                             currentCity,
                                             currentDate }, exclusive = false, forceDownload = false) => {
    let url = `${API_URL}document/sell`;

    if(exclusive) {
        url = `${url}-exclusive`;
    }

    if(forceDownload) {
        url = `${url}/generate`;
    }

    const response = await axios.get(url, {
        params: {
            propertyName,
            propertyId,
            propertyAddress,
            contractStartType,
            price,
            country,
            propertyLocality,
            currentCity,
            currentDate,
        }});

    if(forceDownload) {
        return response.data.data.download
    }

    return response.data.data.render;
}

export const renderPartnerDocument = async ({ agent, agentId, agentAddress, partnerName, partnerId, location, date, quantity }, forceDownload = false) => {
    let url = `${API_URL}document/partners`;

    if(forceDownload) {
       url = `${url}/generate`;
    }

    const response = await axios.get(url, {
        params: {
            agent,
            agentId,
            agentAddress,
            partnerName,
            partnerId,
            location,
            date,
            quantity
        }});

    if(forceDownload) {
        return response.data.data.download
    }

    return response.data.data.render;
};



export const download = async (fileUrl) => {
    const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
        },
    });

    const blob = await response.blob();
    const blobURL = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobURL;
    a.download = 'documento.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(blobURL);
}