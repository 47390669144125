import './App.css';
import './assets/css/main.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from "./pages/Index";
import Login from "./pages/Login";
import Partners from "./pages/documents/Partners";
import Sell from "./pages/documents/Sell";
import SellExclusive from "./pages/documents/SellExclusive";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
      <div>
          <Router>
              <Routes>
                  <Route path="/login" element={<Login />} />

                  {/* Ruta protegida */}
                  <Route
                      path="/documents/partners"
                      element={
                          <ProtectedRoute>
                              <Partners />
                          </ProtectedRoute>
                      }
                  />
                  <Route
                      path="/documents/sell"
                      element={
                          <ProtectedRoute>
                              <Sell />
                          </ProtectedRoute>
                      }
                  />
                  <Route
                      path="/documents/sell-exclusive"
                      element={
                          <ProtectedRoute>
                              <SellExclusive />
                          </ProtectedRoute>
                      }
                  />
                  <Route
                      path="/"
                      element={
                          <ProtectedRoute>
                              <Index />
                          </ProtectedRoute>
                      }
                  />
              </Routes>
          </Router>
      </div>
  );
}

export default App;
