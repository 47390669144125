import React, {createContext, useState} from 'react';
import {isAuthenticated, loginWithEmail, logOut} from "./AuthService"

export const AuthContext = createContext({
    user: {
        name: null,
    },
    accessToken: null,
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userAuthenticated, setUserAuthenticated] = useState(isAuthenticated());

    const login = async (email, password) => {
        const data = await loginWithEmail(email, password);
        setUser(data);
    };

    const logout = () => {
        setUser(null);
        logOut()
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, userAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};
