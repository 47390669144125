import axios from 'axios';
import { API_URL } from '../config/constants'


export const loginWithEmail = async (email, password) => {
    const response = await axios.post(`${API_URL}auth/login`, { email, password });
    const data = response.data.data;

    const authData = {
        authToken: data.access_token,
        createdAt: Date.now(),
        user: data.user
    }

    localStorage.setItem('auth.data', JSON.stringify(authData));

    return authData;
};

export const isAuthenticated = () => {
    return Boolean(localStorage.getItem('auth.data'));
}

export const logOut = () => {
    localStorage.removeItem('auth.data');
};
