import Nav from "../components/Nav";

export default function Index() {
    return <div>
        <Nav/>
        <div className="page page-center">
            <div className="container container-xl">
                <div className="row">
                    <div className="col-12">
                        <div className="card p-5">
                            <h1>DASHBOARD</h1>
                            <p>working on it, keep calm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}