import Nav from "../../components/Nav";
import {useEffect, useState} from "react";
import {download, renderPartnerDocument, renderSellDocument} from "./DocumentService";

export default function Partners() {
    const [propertyName, setPropertyName] = useState('')
    const [propertyId, setPropertyId] = useState('')
    const [propertyAddress, setPropertyAddress] = useState('')
    const [contractDuration, setContractDuration] = useState('')
    const [contractStartType, setContractStartType] = useState('')
    const [price, setPrice] = useState('')
    const [country, setCountry] = useState('')
    const [propertyLocality, setPropertyLocality] = useState('')
    const [currentCity, setCurrentCity] = useState('')
    const [currentDate, setCurrentDate] = useState('')

    const [r, setR] = useState('')

    const fetchPreview = async () => {
        const render = await renderSellDocument({
            propertyName,
            propertyId,
            propertyAddress,
            contractStartType,
            price,
            country,
            propertyLocality,
            currentCity,
            currentDate,
        })

        setR(render);
    };

    const forceDownload = async () => {
        const fileUrl = await renderSellDocument({
            propertyName,
            propertyId,
            propertyAddress,
            contractStartType,
            price,
            country,
            propertyLocality,
            currentCity,
            currentDate,
        }, false, true)

        await download(fileUrl)
    }


    return <div>
        <Nav />
        <div className="page page-center">
            <div className="container container-xl">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title text-uppercase">Sell contract (NOT EXCLUSIVE)</h3>
                                    </div>
                                    <div className="card-body">

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label text-uppercase">Variables</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <label className="form-label text-uppercase">Value</label>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">Nombre</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPropertyName(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">DNI</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPropertyId(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3 pt-2">
                                                <label className="form-label required">Address</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPropertyAddress(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Fecha inicio contrato</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setContractStartType(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Precio</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPrice(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Pais</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setCountry(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Ciudad propiedad</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setPropertyLocality(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Ciudad firma</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setCurrentCity(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="row row-cards">
                                            <div className="mb-3 col-sm-4 col-md-3">
                                                <label className="form-label required">Fecha firma</label>
                                            </div>
                                            <div className="mb-3 col-sm-8 col-md-9">
                                                <input type="text" className="form-control"
                                                       onChange={(e) => setCurrentDate(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-end">
                                        <button type="button" className="btn btn-primary mx-2"
                                                onClick={forceDownload}>Download
                                        </button>
                                        <button type="submit" className="btn btn-primary"
                                                onClick={fetchPreview}>Preview
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 p-5 bg-white" dangerouslySetInnerHTML={{__html: r}}></div>
                </div>
            </div>
        </div>
    </div>
}